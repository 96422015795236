import image1 from "../assets/T-image1.jpg";
import image2 from "../assets/T-image2.png";
import image3 from "../assets/T-image3.jpg";
import image4 from "../assets/T-image4.png";
import image5 from "../assets/T-image5.jpeg";
import image6 from "../assets/T-image6.jpeg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Tu torniquete o molinete  de acceso es el punto de control más valioso. Tus clientes ingresaran solo con su rostro o un código QR. Altas y Bajas en línea sin desfase",
    name: 'CONTROL DE ACESSO BIOMETRICO',

  },
  {
    image: image2,
    review: 'Carga tu propio programa de ejercicios y entrenamiento en nuestra app personalizada. Sube fotografías o videos de tu equipo. Revisa historial y evolución física en linea.',
    name: 'PROGRAMA DE ENTRENAMIENTO DIGITAL',

  },
  {
    image: image3,
    review: 'Nuestro avanzado algoritmo proceso cargos recurrentes en tarjetas de crédito y debito, sin necesidad de nóminas ni intervención humana. Emitiendo contratos, boletas y facturas electrónicas en línea, 100% paperless (Cero papel)',
    name: 'PROCESAMIENTO DE CARGOS ELECTRONICOS RECURRENTES',

  },
  {
    image: image4,
    review: 'Gestiona los cupos de tus clases grupales. Agenda citas con entrenadores para evaluación física, nutricional o kinesiología. Evalua la asistencia y uso de tus instalaciones con información en línea.',
    name: 'RESERVA (BooKing) DE CLASES Y SERVICIOS',

  },
  {
    image: image5,
    review: 'Fideliza a tus clientes a través de una app para Apple y Android con tu marca personal. comunicación en línea y servicios digitales para tus usuarios fitness',
    name: 'APLICACION MOVIL PERZONALIZADA',

  },
  {
    image: image6,
    review:
      "La información de tu negocio disponible en mas de 40 reportes en línea, descargables en formato Excel y tableros de Business Intelligence para el control de tus indicadores claves de procesos (KPI)",
    name: 'REPORTES Y DASHBOARD',

  }
];
